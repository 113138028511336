import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from "./routes/protected/ProtectedRoute";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserOnRefresh } from "./store/actions/UserActions";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import Navbar from "./reusable/Navbar";
import LoggedInProtectedRoute from "./routes/protected/LoggedInProtectedRoute";
import UserDeleteMessage from "./pages/UserDeleteMessage";
import PageNotFound from "./pages/PageNotFound";

function App() {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.UserReducer);

  console.log(userState);

  useEffect(() => {
    if (!userState.user) {
      let token = localStorage.getItem("Appusertoken");
      if (token) {
        dispatch(getUserOnRefresh(token, toast));
      }
    }
  }, []);

  // if (userState?.appLoading) {
  //   return (
  //     <div className="text-center mt-5">
  //       <div className="spinner-border" role="status">
  //         <span className="visually-hidden">Loading...</span>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route
          path="/login"
          element={
            <LoggedInProtectedRoute>
              <Login />
            </LoggedInProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              {userState?.appLoading ? (
                <div className="text-center mt-5">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <Home />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/deleted"
          element={
            <ProtectedRoute>
              <UserDeleteMessage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>

      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}

export default App;
