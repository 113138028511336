import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUserAction } from "../store/actions/UserActions";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userState = useSelector((state) => state.UserReducer);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let [formErrors, setFormErrors] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validator = {};
    if (email.trim() === "") {
      validator.email = "Email is required";
    }
    if (password.trim() === "") {
      validator.password = "Password is required";
    }

    if (Object.keys(validator).length > 0) {
      setFormErrors(validator);
    } else {
      setFormErrors(null);
      dispatch(loginUserAction({ email, password }, toast, navigate));
    }
  };

  return (
    <div className="container">
      <div className="row d-flex mt-5 justify-content-center">
        <div className="col-lg-6   mt-5   col-md-8 col-12">
          <div className="bg-white rounded shadow p-lg-5 p-3">
            <h2 className="text-center">Login</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => {
                    let validatorErrors = { ...formErrors };
                    validatorErrors?.email && delete validatorErrors?.email;
                    setFormErrors(validatorErrors);
                    setEmail(e.target.value);
                  }}
                  placeholder="Enter your email"
                />
                {formErrors?.email && (
                  <small className="text-danger pt-2">
                    {formErrors?.email}
                  </small>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label ">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => {
                    let validatorErrors = { ...formErrors };
                    validatorErrors?.password &&
                      delete validatorErrors?.password;
                    setFormErrors(validatorErrors);
                    setPassword(e.target.value);
                  }}
                  placeholder="Enter your password"
                />
                {formErrors?.password && (
                  <small className="text-danger pt-2">
                    {formErrors?.password}
                  </small>
                )}
              </div>
              <button type="submit" className="btn btn-success mt-2 w-100">
                {userState.loading ? (
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ width: "20px", height: "20px" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Login"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
