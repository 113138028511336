import API from "../../api.json";
import axios from "axios";
import {
  SET_APP_LOADING_FALSE,
  SET_APP_LOADING_TRUE,
  SET_DELETE_USER_LOADING_FALSE,
  SET_DELETE_USER_LOADING_TRUE,
  SET_USER_AFTER_LOGIN,
  SET_USER_AFTER_LOGOUT,
  SET_USER_AFTER_REFRESH,
  SET_USER_LOGIN_LOADING_FALSE,
  SET_USER_LOGIN_LOADING_TRUE,
} from "../constants/UserContants";

export const loginUserAction =
  ({ email, password }, toast, navigate) =>
  async (dispatch) => {
    try {
      dispatch({ type: SET_USER_LOGIN_LOADING_TRUE });
      const loginApiRequest = await axios.post(
        `${API.BACKEND_API_URL}/user/login`,
        {
          email,
          password,
        }
      );
      const loginApiResponse = await loginApiRequest.data;
      if (loginApiResponse.type == "error") {
        toast.error(loginApiResponse?.message, { id: 2 });
        dispatch({ type: SET_USER_LOGIN_LOADING_FALSE });
      } else {
        dispatch({ type: SET_USER_LOGIN_LOADING_FALSE });
        dispatch({
          type: SET_USER_AFTER_LOGIN,
          payload: {
            token: loginApiResponse?.token,
            user: loginApiResponse?.user,
          },
        });
        navigate("/");
      }
    } catch (error) {
      dispatch({ type: SET_USER_LOGIN_LOADING_FALSE });
      toast.error(error?.response?.data?.message, { id: 2 });
    }
  };

export const getUserOnRefresh = (token, toast) => async (dispatch) => {
  try {
    dispatch({ type: SET_APP_LOADING_TRUE });
    let getUserRequest = await axios.get(
      `${API.BACKEND_API_URL}/user/get/user`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    let getUserResponse = getUserRequest.data;

    if (getUserResponse?.type == "error") {
      dispatch({ type: SET_APP_LOADING_FALSE });
      toast.error(getUserResponse?.message, { id: 1 });
      dispatch({ type: SET_USER_AFTER_LOGOUT });
    } else {
      console.log("getUserResponse", getUserResponse);
      dispatch({ type: SET_APP_LOADING_FALSE });
      dispatch({ type: SET_USER_AFTER_REFRESH, payload: getUserResponse.user });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: SET_APP_LOADING_FALSE });
    toast.error(error?.response?.data?.message, { id: 1 });
    dispatch({ type: SET_USER_AFTER_LOGOUT });
  }
};

export const deleteUserAPIAction = (toast, navigate) => async (dispatch) => {
  try {
    let token = localStorage.getItem("Appusertoken");
    if (!token) {
      let findButton = document.getElementById("modelForDeleteUserButton");
      findButton?.click();
      dispatch({ type: SET_USER_AFTER_LOGOUT });
      navigate("/login");
    } else {
      dispatch({ type: SET_DELETE_USER_LOADING_TRUE });
      const apiRequest = await axios.post(
        `${API.BACKEND_API_URL}/user/delete/user`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const apiData = await apiRequest.data;
      console.log(apiData);
      if (apiData?.type == "error") {
        if (apiData?.status == 401) {
          dispatch({ type: SET_DELETE_USER_LOADING_FALSE });
          let findButton = document.getElementById("modelForDeleteUserButton");
          findButton?.click();
          toast.error(apiData?.message, { id: 1 });
          dispatch({ type: SET_USER_AFTER_LOGOUT });
        } else {
          dispatch({ type: SET_DELETE_USER_LOADING_FALSE });
          toast.error(apiData?.message, { id: 4 });
        }
      }

      if (apiData?.type == "success") {
        toast.success(apiData?.message, { id: 3 });
        let findButton = document.getElementById("modelForDeleteUserButton");
        findButton?.click();
        navigate("/user/deleted");
        setTimeout(() => {
          dispatch({ type: SET_USER_AFTER_LOGOUT });
        }, 5000);
      }
      dispatch({ type: SET_DELETE_USER_LOADING_FALSE });
    }
  } catch (error) {
    dispatch({ type: SET_DELETE_USER_LOADING_FALSE });
    toast.error(error?.response?.data?.error?.message, { id: 4 });
  }
};
