import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteUserAPIAction } from "../store/actions/UserActions";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userState = useSelector((state) => state.UserReducer);
  const deleteAccountApi = () => {
    dispatch(deleteUserAPIAction(toast, navigate));
  };

  return (
    <>
      <div className="mt-5 pt-3 container">
        <h2 className="fs-1">
          Hi,{" "}
          {userState?.user &&
            userState?.user?.first_name.charAt(0).toUpperCase() +
              userState?.user?.first_name.slice(1)}{" "}
          {userState?.user &&
            userState?.user?.last_name.charAt(0).toUpperCase() +
              userState?.user?.last_name.slice(1)}{" "}
        </h2>
        <br />
        <br />
        <h4>Thank you for logging into your account.</h4>
        <div className="row">
          <div className="col-lg-6 col-md-8">
            <div className="fs-4 pt-5">
              This web page allows you to delete all your account information.
            </div>
            <div className="fs-4">
              If you're sure you want to delete your account, including your
              vehciles/reminders/expenses - please proceed as below.
            </div>
            <div className="alert alert-warning mt-3 " role="alert">
              <div className="fs-5">
                Warning : Please note that once an account is deleted, your data
                will be permanently removed from our system and there will be no
                way of restoration.
              </div>
            </div>
            <div className="form-check mt-3 d-flex ">
              <input
                className="form-check-input "
                onClick={(e) => {
                  setChecked(e.target.checked);
                }}
                type="checkbox"
                value=""
                id="flexCheckChecked"
                style={{ border: "1px solid black" }}
              />
              <label
                className="form-check-label "
                htmlFor="flexCheckChecked"
                style={{ fontSize: "18px", paddingLeft: "10px" }}
              >
                I acknowledge and still want to delete my account in "Pakistan
                Petrol Price today"
              </label>
            </div>
            <button
              className="btn btn-lg btn-danger mt-4"
              disabled={!checked}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Delete my account permanently
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg  modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-body">
              <div className="d-flex align-items-center justify-content-between">
                <h5>Are you sure you want to delete your account?</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="alert alert-warning mt-3" role="alert">
                <div className="fs-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                  </svg>{" "}
                  I hereby confirm that I want to delete my account and all the
                  relevant data.
                </div>
              </div>
              {/* <p>
                I hereby confirm that that I want to delete my account and all
                the relevant data.  
              </p> */}
              <div className="text-end d-md-flex align-items-center justify-content-between">
                <button
                  className="btn btn-success mb-2 mb-md-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="modelForDeleteUserButton"
                >
                  No, I don't want to delete
                </button>
                <button className="btn btn-danger " onClick={deleteAccountApi}>
                  Delete my account and all the information permanently{" "}
                  {userState?.deleteUserLoading && (
                    <div
                      className="spinner-border"
                      role="status"
                      style={{ width: "20px", height: "20px" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
