import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const LoggedInProtectedRoute = ({ children }) => {
  const userState = useSelector((state) => state.UserReducer);
  if (userState.token) {
    return <Navigate to={"/"} replace />;
  }
  return children;
};

export default LoggedInProtectedRoute;
