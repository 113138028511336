import {
  SET_APP_LOADING_FALSE,
  SET_APP_LOADING_TRUE,
  SET_DELETE_USER_LOADING_FALSE,
  SET_DELETE_USER_LOADING_TRUE,
  SET_USER_AFTER_LOGIN,
  SET_USER_AFTER_LOGOUT,
  SET_USER_AFTER_REFRESH,
  SET_USER_LOGIN_LOADING_FALSE,
  SET_USER_LOGIN_LOADING_TRUE,
} from "../constants/UserContants";

const initialState = {
  token: localStorage.getItem("Appusertoken") || null,
  user: null,
  loading: false,
  deleteUserLoading: false,
  appLoading: false,
};

const UserReducer = (state = initialState, action) => {
  if (action.type == SET_USER_LOGIN_LOADING_TRUE) {
    return { ...state, loading: true };
  }

  if (action.type == SET_USER_LOGIN_LOADING_FALSE) {
    return { ...state, loading: false };
  }

  if (action.type == SET_USER_AFTER_LOGIN) {
    localStorage.setItem("Appusertoken", action.payload.token);
    return { ...state, user: action.payload.user, token: action.payload.token };
  }

  if (action.type == SET_USER_AFTER_REFRESH) {
    return { ...state, user: action.payload };
  }

  if (action.type == SET_USER_AFTER_LOGOUT) {
    localStorage.removeItem("Appusertoken");
    return { ...state, user: null, token: null };
  }

  if (action.type == SET_DELETE_USER_LOADING_FALSE) {
    return { ...state, deleteUserLoading: false };
  }

  if (action.type == SET_DELETE_USER_LOADING_TRUE) {
    return { ...state, deleteUserLoading: true };
  }
  if (action.type == SET_APP_LOADING_TRUE) {
    return { ...state, appLoading: true };
  }
  if (action.type == SET_APP_LOADING_FALSE) {
    return { ...state, appLoading: false };
  }

  return state;
};

export default UserReducer;
