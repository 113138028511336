import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import UserReducer from "./reducers/UserReducer";

const reducers = combineReducers({
  UserReducer,
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;
