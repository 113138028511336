import React from "react";

const UserDeleteMessage = () => {
  return (
    <div className="container">
      <div className="row mt-5 d-flex justify-content-center">
        <div className="col-lg-6 mt-5 shadow rounded p-5 text-center text-dark display-6 bg-white">
          Your Account and all its data have deleted successfully.
        </div>
      </div>
    </div>
  );
};

export default UserDeleteMessage;
