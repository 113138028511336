import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_USER_AFTER_LOGOUT } from "../store/constants/UserContants";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.UserReducer);
  const location = useLocation();

  const logoutUser = () => {
    dispatch({ type: SET_USER_AFTER_LOGOUT });
    navigate("/login");
  };

  return (
    <div className="container pt-4 d-flex align-items-center justify-content-between">
      <a href="https://petrolprices.pk/">
        <img alt="applogo" src="./logo.webp" style={{ cursor: "pointer" }} />
      </a>
      {location.pathname !== "/user/deleted" && userState?.token && (
        <button className="btn btn-warning" onClick={logoutUser}>
          Logout
        </button>
      )}
    </div>
  );
};

export default Navbar;
